import React, { useRef } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";
import sobre from "../images/abrir-correo.png";

function Contacto() {
  const refForm = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(refForm.current);

    const serviceId = "service_2qvlo1a";
    const templateId = "template_alngjpa";
    const apikey = "9n46BX01bNY5TqND6";

    emailjs.sendForm(serviceId, templateId, refForm.current, apikey)
    .then( result => console.log(result.text) )
    .catch(error => console.error(error));
    const btnText = document.querySelector("#btnMail");
    btnText.innerHTML = "¡Enviado!";
    btnText.classList.add("activeMail");
    btnText.setAttribute("disabled", "")
    document.getElementById('inpName').setAttribute("disabled", "")
    document.getElementById('inpEmail').setAttribute("disabled", "")
    document.getElementById('inpMsj').setAttribute("disabled", "")

    document.getElementById('inpName').style.transition = "0.3s"
    document.getElementById('inpName').style.opacity = "0.5"
    document.getElementById('inpEmail').style.transition = "0.3s"
    document.getElementById('inpEmail').style.opacity = "0.5"
    document.getElementById('inpMsj').style.transition = "0.3s"
    document.getElementById('inpMsj').style.opacity = "0.5"
  }

  return (
    <>
      <section id="contacto" className="contacto">
      <h2><span>Contacto</span>
      </h2>

        <div className="contactFormContainer">
          <img src={sobre} alt="designed by kliwir art from Flaticon" />
    <form ref={refForm} action="" onSubmit={handleSubmit}>
      <input type="text" id="inpName" name="nombre" placeholder="Nombre" required/>
      <input type="email" id="inpEmail" name="emailRmt" placeholder="Email" required/>
      <textarea name="mensaje" id="inpMsj" cols="30" rows="10" placeholder="Mensaje" required></textarea>


      <div className="btnMailContainer">

        <button id="btnMail">
            Enviar
        </button>
      </div>


    </form>

        </div>

      </section>
    </>
  );
}

export default Contacto;
