import React from "react";
import "./experiencia.css";
import eivorLogo from "../images/logoEivor.svg";
import fulpLogo from "../images/fulp.png";
import suiscaLogo from "../images/suisca.png";

function Acordeon() {
  return (
    <>
      <section id="experiencias" className="experiencias">
      <h2><span>Experiencia</span><span>Profesional</span></h2>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                <span>
                <span>Desarrollador web | Suisca Group </span><span> 2023 - Actualidad</span>
                </span>
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <div>
                  <div className="expTopInfo">
                    <span><i className="fas fa-map-marker-alt"></i> Las Palmas, España</span><span><i className="fas fa-external-link-square-alt"></i><a target="_blank" href="https://suiscagroup.com">suiscagroup.com</a></span>
                  </div>
                  <p>
                  {/* Developing front-end and mobile app solutions (B2C, B2B) in Travel Tech on React/Next.js framework and Flutter SDK. */}
                  Desarrollo de aplicaciones para encontrar soluciones a tareas automatizables utilizando diversas tecnologías, como React, PHP y Laravel...
                  </p>
                  <div className="expBottomInfo">
                    <ul>
                        <li>React</li>
                        <li>Laravel</li>
                        <li>PL/SQL</li>
                        <li>Oracle</li>
                        <li>SEO</li>
                        <li>Automatización de procesos</li>
                        <li>Implementación de IA</li>
                    </ul>
                  </div>
                </div>
                <div className="expImage">
                  <img src={suiscaLogo} />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed accordionExpBtn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <span>
                <span>Desarrollador web | EivorSystems </span><span> 2023</span>
                </span>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <div>
                  <div className="expTopInfo">
                    <span><i className="fas fa-map-marker-alt"></i> Las Palmas, España</span><span><i className="fas fa-external-link-square-alt"></i><a target="_blank" href="https://eivor.es">eivor.es</a></span>
                  </div>
                  <p>
                  Desarrollo de sitios web estáticos mediante tecnologías de plantillas como WordPress, incluyendo la programación de plugins, la creación de temas y la inserción de código en temas predefinidos.
                  </p>
                  <div className="expBottomInfo">
                    <ul>
                        <li>Javascript</li>
                        <li>PHP</li>
                        <li>MySql</li>
                        <li>Plugins</li>
                        <li>Contacto con clientes</li>
                        <li>Docker</li>
                        <li>WordPress</li>
                        
                    </ul>
                  </div>
                </div>
                <div className="expImage">
                  <img src={eivorLogo} />
                </div>
              </div>
            </div>
          </div>

        <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <span>
                <span>Desarrollador web | FULP </span><span> 2022 - 2023</span>
                </span>
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              <div>
                  <div className="expTopInfo">
                    <span><i className="fas fa-map-marker-alt"></i> Las Palmas, España</span><span><i className="fas fa-external-link-square-alt"></i><a target="_blank" href="https://fulp.es">fulp.es</a></span>
                  </div>
                  <p>
                  Primera incursión en el mundo de la programación Front-end, aprovechando al máximo la valiosa oportunidad de formación recibida durante el desempeño laboral.
                  </p>
                  <div className="expBottomInfo">
                    <ul>
                        <li>Javascript</li>
                        <li>PHP</li>
                        <li>MySql</li>
                        <li>React</li>
                        <li>Scrum</li>
                    </ul>
                  </div>
                </div>
                <div className="expImage">
                  <img src={fulpLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Acordeon;
