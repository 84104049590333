import React, { Component } from "react";
import logo from "../images/logo.svg";
import "./NavbarStyles.css";

class Navbar extends Component {
  state = { clicked: false, scrolled: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <>
        <nav className={this.state.scrolled ? "fixed" : ""}>
          <a href="#">
            <img src={logo} alt="jonay.dev" />
          </a>

          <div>
            <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"}>
              <li>
                <a href="#">// inicio</a>
              </li>
              <li>
                <a href="#tecnologias">// tecnologías</a>
              </li>
              {/* <li>
                <a href="#">// proyectos</a>
              </li> */}
              <li>
                <a href="#experiencias">// experiencia</a>
              </li>
              <li>
                <a href="#contacto">// contacto</a>
              </li>
            </ul>
          </div>
          <div id="mobile" onClick={this.handleClick}>
            <i id="bar" className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
